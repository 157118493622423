const teamData = [
    {
        id: 1,
        category: "Al McGee",
        bio: `Al became an Oregon-licensed private investigator in 2019. He is skilled in paying attention to details, leadership, report writing, and communication. He currently serves as a Private Security/Investigator Policy Committee member with the Oregon Department of Public Safety Standards and Training (DPSST).

Al was born in Riverside, California and raised in Compton and Long Beach. He began his career in 1986 when he earned his diploma in Law Enforcement and Security from the Metropolitan Technical Institute.

He began working in the Los Angeles area in the private sector, where he conducted a full range of investigative and protective services ranging from Loss Prevention, Plain Clothes Operations, Personal Protection, Insurance Fraud, and Corporate Investigations. In 1991, Al moved to Oregon, where he continued his career in the private sector, also earning an Associates degree in Criminal Justice. 

Al serves as the Squadron Soldier and Family Readiness Group Advisor/Coordinator for the Washington National Guard 1-303rd Cavalry Regiment. Al enjoys spending time with his family, cooking, hunting, and fishing, when he is not working.`,
        states: ["OR", "WA"],
        title: "Licensed Private Investigator"
    },
    {
        id: 3,
        category: "Cristina Lougal",
        bio: "Bio Coming Soon",
        states: ["OR", "CT"],
        title: "Licensed Private Investigator"
    },
    {
        id: 4,
        category: "Steven Lougal",
        bio: "Bio Coming Soon",
        states: ["OR", "CT"],
        title: "Licensed Private Investigator"
    },
    {
        id: 5,
        category: "Gil Del Valle",
        bio: "Bio Coming Soon",
        states: ["CT"],
        title: "Licensed Private Investigator"
    }
];

export default teamData;
